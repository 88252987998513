<template>
  <div class="custom">
    <!-- banner图片 -->
    <div class="banner"></div>
    <div class="sec1">
      <div class="sec1Tit">智能定制服务</div>
      <div class="sec1Text">我们专业的定制服务团队结合客户实际需求与房间布局，</div>
      <div class="sec1Text sec1Texts">为客户定制专属的产品解决方案和场景设计。</div>
    </div>
    <!-- 01 -->
    <div class="sec2">
      <div class="li">
        <div class="liLeft">
          <div class="num">01</div>
        </div>
        <div class="liRight">
          <div class="liRone">
            <div class="oneTit">定制服务咨询</div>
            <div class="oneText">您可以通过拨打我们的电话 400-000-7027 </div>
             <div class="oneText oneTexts">来快速了解我们提供的服务，或者与我们进</div>
              <div class="oneText oneTexts">行合作咨询。</div>
          </div>
          <div class="liRtwo">
            <div class="twoText">
              <div class="tLeft">便捷</div>
              <div class="tRight">可致电人工客服快速了解合作信息</div>
            </div>
            <div class="twoText">
              <div class="tLeft">高效</div>
              <div class="tRight">可通过客服快速获取合作建议</div>
            </div>
          </div>
        </div>
      </div>
      <div class="li">
        <img src="../../assets/index/custom1.png" alt class="img" />
      </div>
    </div>
    <!-- 02 -->
    <div class="sec2">
      <div class="li">
        <img src="../../assets/index/custom2.png" alt class="img" />
      </div>
      <div class="li lis">
        <div class="liLeft">
          <div class="num">02</div>
        </div>
        <div class="liRight">
          <div class="liRone">
            <div class="oneTit">定制和考察分析</div>
            <div class="oneText">确定合作意愿后，我们的工程师将上门为</div>
             <div class="oneText oneTexts">您考察评估，与您沟通需求，并为您量身</div>
              <div class="oneText oneTexts">定制智能酒店解决方案。</div>
          </div>
          <div class="liRtwo">
            <div class="twoText">
              <div class="tLeft">专业</div>
              <div class="tRight">我们的专业工程师直接为您服务</div>
            </div>
            <div class="twoText">
              <div class="tLeft">高效</div>
              <div class="tRight">一对一服务沟通更高效</div>
            </div>
            <div class="twoText">
              <div class="tLeft">定制</div>
              <div class="tRight">工程师与您沟通需求并量身定制智能酒店解决方案</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 03 -->
    <div class="sec2">
      <div class="li">
        <div class="liLeft">
          <div class="num">03</div>
        </div>
        <div class="liRight">
          <div class="liRone">
            <div class="oneTit">上门安装调试</div>
            <div class="oneText">我们的工程师将上门为您提供专业的安装</div>
              <div class="oneText oneTexts">与调试服务。</div>
          </div>
          <div class="liRtwo">
            <div class="twoText">
              <div class="tLeft">安装</div>
              <div class="tRight">专业人员上门安装</div>
            </div>
            <div class="twoText">
              <div class="tLeft">调试</div>
              <div class="tRight">调试系统及设备至正常状态</div>
            </div>
          </div>
        </div>
      </div>
      <div class="li">
        <img src="../../assets/index/custom3.png" alt class="img" />
      </div>
    </div>
    <!-- 04 -->
    <div class="sec2">
      <div class="li">
        <img src="../../assets/index/custom4.png" alt class="img" />
      </div>
      <div class="li lis">
        <div class="liLeft">
          <div class="num">04</div>
        </div>
        <div class="liRight">
          <div class="liRone">
            <div class="oneTit">产品需求调试</div>
            <div class="oneText">我们将根据为您量身定制智能酒店解决方案，</div>
             <div class="oneText oneTexts">上门或远程调试您想要的功能或联动场景。</div>
          </div>
          <div class="liRtwo">
            <div class="twoText">
              <div class="tLeft">专业</div>
              <div class="tRight">软件工程师联动调试</div>
            </div>
            <div class="twoText">
              <div class="tLeft">定制</div>
              <div class="tRight">工程师为您量身定制</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 05 -->
    <div class="sec2">
      <div class="li">
        <div class="liLeft">
          <div class="num">05</div>
        </div>
        <div class="liRight">
          <div class="liRone">
            <div class="oneTit">智能维护服务</div>
            <div class="oneText">BOT 超级主机具有远程检测和调试功能，</div>
            <div class="oneText oneTexts">发现问题您可随时联系我们。</div>
          </div>
          <div class="liRtwo">
            <div class="twoText">
              <div class="tLeft">智能</div>
              <div class="tRight">远程即可发现问题</div>
            </div>
            <div class="twoText">
              <div class="tLeft">高效</div>
              <div class="tRight">远程即可为您解决问题</div>
            </div>
            <div class="twoText">
              <div class="tLeft">上门</div>
              <div class="tRight">其他问题安排人工上门服务</div>
            </div>
          </div>
        </div>
      </div>
      <div class="li">
        <img src="../../assets/index/custom5.png" alt class="img" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  // height: 800px;
  height: 8rem;
  background: url("../../assets/index/customBanner.png") no-repeat center center;
  background-size: cover;
}
.sec1 {
  width: 100%;
  padding: 1.2rem 0px 1.36rem 0px;
  background-color: #fff;
  color: #333333;
  .sec1Tit {
    font-size: 36px;
    line-height: 36px;
    font-weight: bold;
  }
  .sec1Text {
    margin-top: 0.38rem;
    font-size: 24px;
    line-height: 24px;
    font-weight: 400;
  }
  .sec1Texts {
    margin-top: 0.12rem;
  }
}
.sec2 {
  width: 100%;
  min-width: 1200px;
  display: flex;
  flex-wrap: wrap;
  .li {
    flex: 1;
    background-color: #f8f8f8;
    display: flex;
    color: #333;
    margin-bottom: 0.3rem;

    .liLeft {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      .num {
        -webkit-text-stroke: 2px rgba(255, 144, 0, 0.5);
        -webkit-text-fill-color: transparent;
        font-size: 2.4rem;
        text-shadow: 7px 7px 1px rgba(255, 144, 0, 0.2);
        font-weight: bold;
      }
    }
    .liRight {
      flex: 2;
      border-left: 2px solid #fff;
      color: #333333;
      display: flex;
      flex-direction: column;

      .liRone {
        width: 100%;
        height: 67%;
        padding-left: 0.45rem;
        border-bottom: 2px solid #fff;
        text-align: left;
        display: flex;
        flex-direction: column;
        .oneTit {
          margin-top: 0.46rem;
          font-size: 0.36rem;
          line-height: 0.36rem;
          font-weight: bold;
          text-align: justify;
        }
        .oneText {
          margin-top: 0.39rem;
          font-size: 0.24rem;
          line-height: 0.24rem;
          font-weight: 400;
        }
        .oneTexts{
          margin-top: 0.16rem;
        }
      }
      .liRtwo {
        width: 100%;
        height: 33%;
        padding-left: 0.45rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .twoText {
          display: flex;
          font-size: 0.18rem;
          line-height: 0.18rem;
          color: #333333;
          margin-top: 0.19rem;
          .tLeft {
            margin-right: 0.1rem;
             font-weight: 400;
          }
          .tRight {
            color: #666;
          }
          &:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
    &:nth-of-type(2n + 1) {
      margin-right: 0.3rem;
    }
    .img {
      width: 100%;
    }
  }
  .lis {
    display: flex;
    flex-direction: row-reverse;
    .liRight {
      border-left: 0px;
      border-right: 2px solid #fff;
    }
  }
  &:last-of-type{
      padding-bottom: 1.2rem;
        .li{
            margin-bottom: 0px;
        }
  }
}


</style>